#PaymentHistory{
    #detail-modal{
    }

    .text-label{
        color: var(--dark-color-light);
    }

    .detail-scroll{
        height: fit-content;
        min-height: 240px;
        max-height: 240px;
        overflow-y: scroll;
    }

    .empty-state{
        width: 100%;
        height: 210px;
    }
}
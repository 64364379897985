/* スイッチ全体のスタイル */
.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 42px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    
    /* スライダーのスタイル */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f0f0f0;
        transition: 0.4s;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        &:before {
            position: absolute;
            content: "";
            height: 32px;
            width: 32px;
            left: 5px;
            bottom: 5px;
            background-color: white;
            transition: 0.4s;
            border-radius: 4px;
            transform: translateX(0);
        }
    }
    
    input:checked + .slider {
        &:before {
            transform: translateX(38px);
        }
    }

    /* アイコンのスタイル */
    .icon {
        position: relative;
        z-index: var(--z-index-local-over-1);
        color: #555;
        transition: 0.3s;

        &.inactive {
            color: #ccc;
        }

        &.icon-right{
            padding-left: 1px;
        }
        &.icon-left{
            padding-right: 2px;
        }
    }
}

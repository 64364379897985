.FinCodeCardInput {
    width: 100%;

    #fincode-form{
        box-shadow: none !important;
        min-height: 632px !important; 
        max-height: 632px !important; 

        #fincode{
            min-height: 458px !important; 
            max-height: 458px !important; 

            .credit-normal-list{
                display: none !important;
            }
        }

    }
}
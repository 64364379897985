.select-area {
  position: relative;
  z-index: var(--z-index-local-default);
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  color: var(--font_active_color);

  background-color: var(--input-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;

  width: 100%;

  .select-box{
    position: relative;
    z-index: var(--z-index-local-over-1);
    box-sizing: border-box;

    width: 100%;
    height: 40px;
    padding: 0 40px 0 12px;

    
    color: var(--font_active_color);
    font-size: 14px;
    cursor: pointer;
    background: 0 0;
    border: none;
    outline: 0;
    -webkit-appearance: none;
    appearance: none;

  }
  .select-icon{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 12px;
  }
}
.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: var(--z-index-loading);
    display: flex;
    align-items: center;
    justify-content: center;
    
    .loading {
      background: var(--color-white);
      width: 80vw;
      max-width: 400px;
      border-radius: var(--radius);
      padding: 2rem 1rem;
      text-align: center;
    }
    
    .loading h4 {
      margin-bottom: 0;
      line-height: 1.5;
    }
  }
  
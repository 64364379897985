.row-stack {
  display: flex;
  flex-direction: row;

  &.spacing-4px{
    > :not(:first-child) {
      margin-left: 4px;
    }
  }
  &.spacing-8px{
    > :not(:first-child) {
      margin-left: 8px;
    }
  }
  &.spacing-12px{
    > :not(:first-child) {
      margin-left: 12px;
    }
  }
  &.spacing-16px{
    > :not(:first-child) {
      margin-left: 16px;
    }
  }
  &.spacing-32px{
    > :not(:first-child) {
      margin-left: 32px;
    }
  }
}

.row-stack-between
{
  justify-content: space-between;
}
.FlexTextarea {
	position: relative;
	width: 100%;
	height: 180px;

	textarea,
	.textarea_html {
		overflow: hidden;
		box-sizing: border-box;
		padding: 0 8px 0 8px;
		background-color: var(--white-color-lightest);
		width: 100%;
		height: 100%;
		resize: none;
	}
	textarea {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		color: var(--dark-color-dark);
		background-color: var(--input-bg-color);
		background-clip: padding-box;
		border: 1px solid var(--border-color);
		appearance: none;
		border-radius: 4px;
		transition: all 0.15s;
		
		padding: 12px;
		line-height: 12px;

		font-family: inherit;

		&::placeholder {
				color: #c2c8d2;
				opacity: 1;
			}
		
		&:focus {
			background-color: var(--input-bg-color);
			border: 1px solid var(--primary-color-main);
			outline: 0;
		}
	
		&:disabled {
			background: var(--disabled-bg-color);
			color: var(--disabled-font-color);
			cursor: default;
			-webkit-appearance: none;
			border: 1px solid var(--disabled-border-color);
		}
	}


	.textarea_html {
		min-height: 180px;
		white-space: pre-wrap;
		word-wrap: break-word;
		overflow-wrap: break-word;
		color: var(--font_active_color);
		.placeholder {
			color: var(--font_inactive_color);
		}
	}
}

.PlanCard{
    position: relative;
    flex: 1;
    height: 100%;
    padding: 32px 16px;

    .price-text{
        line-height: normal;
    }

    .detail{
        height: 100%;
    }

    .selected-label{
        position: absolute;
        top: -16px;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        background-color: var(--plan-selected-bg);

        width: 120px;
        height: 32px;

        border-radius: 16px;
        color: var(--primary-color-main);
    }
}
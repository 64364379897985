#Account {
    width: 100%;

    /* モバイル */
    @media (width < 752px) {
        --login-min-width: 100vw;
        --login-min-height: 100vh;
    }
    
    /* タブレット */
    @media (752px <= width < 1122px) {
        --login-min-width: 100vw;
        --login-min-height: 100vh;
    }
    
    /* PC */
    @media (1122px <= width) {
        --login-min-width: 420px;
        --login-min-height: 520px;
    }
    
    .title-text{
        font-family: "Inter";
        font-size: 72px;
        letter-spacing: 24px;
        font-weight: 400;

        span{
            font-weight: 200;
        }
    }

    .paper-login {
        width: 100%;
        height: var(--login-min-height);
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logo
    {
        width: calc(527px * 0.55);
        height: auto;
    }

    .divide-half-area{
        padding: 32px;
        &.input-area{
            width: 40vw;
            min-width: 400px;
            background-color: var(--white-color-lightest);
        }
        &.info-area{
            width: 60vw;
            background: var(--main-gradation);

            position: relative;

            &::after{
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(45deg, #F684AA, #D1B6F4, #6395eb);
                opacity: 0.7;
                background-size: 600% 600%;
                animation: GradientBackground 10s ease infinite;
                z-index: var(--z-index-local-over-1);
            }

            .video{
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
    }

    .info-text{
        color: var(--font_active_hidden_color);
        letter-spacing: 1px;
        font-family: "Inter";
        font-weight: 400;
    }

    .copy-text {
        font-size: clamp(16px, 2vw, 48px);
        color: var(--white-color-lightest);
        font-weight: 600;

        text-shadow: 0 0 8px rgba(0, 0, 0, .3);
        z-index: var(--z-index-local-over-2);
    }
    .input-card{

    }
}


@keyframes GradientBackground {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
};

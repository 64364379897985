#AdminUserInfo{
    .list-label{
        min-width: 140px;
    }

    .search-area{
      width: 320px;
    }

    .create-area{
      min-width: 300px;
    }

    .paging-button-area{
      width: 200px;
    }

    .search-button{
      width: 36px;
      height: 36px;
      left: -48px;
      position: relative;
    }
}
#AdminUserChatLog{
    .data-radio-stack{
        margin: 0px 0px  16px 0px;
        padding: 0px 0px 0px 40px;
    }
    .margin-bottom-16px {
        margin-bottom: 16px;
    }
    .dl-button{
        max-width: 12%
    }
    .calender-dialog{
        position: fixed;
        top: 18%;
        right: 40%;
        padding: 8px;
        background-color: var(--white-color-lightest);
        border: 1px solid var(--border-weak-color);
        z-index: var(--z-index-local-over-2);
    }
}
#AdminUserProfileEdit{
  &.page-content{
    max-height: 100vh;
    overflow: hidden;
    justify-content: flex-start;
  }
  
  .editor-wrapper{
    width: 100%;
    height: 100%;
    max-height: 100vh;

    display: flex;
    flex-direction: row;

    .clipboard-area{
      border-radius: 8px;
      overflow: hidden;
      border: solid 1px var(--border-color);

      .textinput{
        border: none;
      }

      .tag-input{
        padding-right: 48px;
        min-width: 280px;
      }
      .clipboard-button{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;

        width: 40px;
        height: 40px;

        border-left: solid 1px var(--border-color);

        border-radius: 0;
        background-color: var(--white-color-lightest);
        color: var(--font_active_sub_color);

        &:hover{
          background-color: var(--white-color-main);
        }
      }
    }

    .editor-drawer{
      // width: 64px;
      min-width: 64px;
      height: 100%;
      max-height: 100vh;
      transition: min-width 0.3s ease;

      background-color: var(--white-color-lightest);
      border-right: solid 1px var(--border-weak-color);

      .drawer-header{
        height: 48px;
        border-bottom: 1px solid var(--white-color-dark);
        padding: 8px;

        .drawer-close-button{
          width: 100%;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
        }
      }

      .drawer-content
      {
        padding: 32px 32px;
        padding-top: 16px;
        min-height: calc(100vh - 96px);
        overflow-y: scroll;
        overflow-x: hidden;
      }

      .editor-drawer-setting, .editor-drawer-movie{
          opacity: 0;
          transition: opacity 0.3s;
          display: none;
          min-width: 360px;
          
          &.show{
              opacity: 1;
              display: initial;
          }
      }

      .back-button{
        .button-label{
          height: 100%;
          width: 100%;
          color: var(--font_active_hidden_color);
        }
      }

      .movie-up-button{
        background-color: var(--input-bg-color);
        border: dashed 2px var(--border-color);
        color: var(--font_active_sub_color);

        &:hover{
          background-color: var(--disabled-bg-color);
        }
      }

      .delete-button{
        height: 28px;
        min-height: 28px;
        padding: 4px;
      }

      .video-button, .video-button-row{
        height: 240px;
        padding: 0;
        // overflow: hidden;
        border: solid 1px var(--border-color);
        position: relative;

        &::after{
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background-color: var(--white-color-lightest-transparent-25);
          z-index: var(--z-index-local-over-1);
          opacity: 0;
          transition: opacity 0.3s;
        }
        &:hover::after{
          opacity: 1;
        }

        .check-mark{
          content: "";
          position: absolute;
          width: 22px;
          height: 22px;
          top: -11px;
          right: -11px;
          border-radius: 11px;
          background-color: var(--primary-color-main);
          z-index: var(--z-index-local-over-2);
          opacity: 0;
          transition: opacity 0.3s;
          color: var(--white-color-lightest);
          padding-top: 1px;
          padding-left: 1px;
        }
        &.selected{
          box-shadow: 0 0 0 2.5px var(--primary-color-main);
          .check-mark{
            opacity: 1;
          }
        }

        .video-label{
          padding: 16px 8px;
          border-bottom: solid 1px var(--border-color);
        }

        .button-label{
          height: 100%;
        }

        .video{
          border-radius: 0px 0px 8px 8px;
        }
      }

      .video-button-row{
        height: 96px;
        border-radius: 4px;

        .video-label{
          padding: 16px;
          border-bottom:none;
        }

        .video{
          width: 35%;
          border-radius: 0px 4px 4px 0px;
        }
      }

      &.expand{
        // width: 420px;
        min-width: 420px;

        .drawer-close-button{
          width: 32px;
        }
      }
    }

    .editor-preview{
      width: 100%;
      height: 100vh;
      overflow: hidden;

      .editor-header{
        box-sizing: border-box;
        background-color: var(--white-color-lightest);
        padding: 16px;

        border-bottom: solid 1px var(--border-weak-color);
      }

      .editor-preview-content{
        padding: 32px;
        overflow-y: scroll;
        height: 100%;

        
        
        .preview-iframe-wrapper{
          &.sp{
            width: clamp(180px, 30vw , 430px);
          }
        }

        .preview-iframe-wrapper.isloading{
          box-shadow: none;
        }
      }
    }
  }
  

  .input-row-area-short{
      width: 50%;
  }
  
  .input-label-text{
      text-align: left;
      width: 18%;
  }
  .long-button{
      max-width: 20%
  }
  .short-input{
      max-width: 20%
  }
  .long-input{
    max-width: 52%
  }
  .addmergin{
      margin-top: 32px;
  }

  .fade-in {
    opacity: 1;
    z-index: var(--z-index-local-over-1); 
  }

  .fade-out {
    opacity: 0;
    z-index: var(--z-index-local-default);
  }
}
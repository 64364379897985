.card-block {
  background-color: var(--white-color-lightest);
  border-radius: 8px;
  border: solid 1px var(--border-weak-color);
  border: none;
  padding: 16px;
  transition: box-shadow 0.3s ease;

  &.popup:hover{
    box-shadow: var(--omni-shadow-hidden);
  }
  
  &.solid-card{
    box-shadow: var(--omni-shadow-hidden);
  }

  &.base-card{
    padding: 40px 40px;
  }

  &.info-card{
    background-color: var(--input-bg-color);
    border: none;
  }

  &.border-card{
    background-color: transparent;
    border: solid 1px var(--border-color);
  }
}
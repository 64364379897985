.stack {
  display: flex;
  flex-direction: column;

  &.spacing-4px{
    > :not(:first-child) {
        margin-top: 4px;
    }
  }
  &.spacing-8px{
    > :not(:first-child) {
      margin-top: 8px;
    }
  }
  &.spacing-12px{
    > :not(:first-child) {
      margin-top: 12px;
    }
  }
  &.spacing-16px{
    > :not(:first-child) {
      margin-top: 16px;
    }
  }
  &.spacing-32px{
    > :not(:first-child) {
      margin-top: 32px;
    }
  }
}
.stepper{
    width: 100%;

    .step-item {
        flex: 0;
        min-width: fit-content;
        width: fit-content;
        align-items: baseline;
        color: var(--primary-color-main);
        transition: color 0.8s;
    
        &.disable {
            color: var(--disabled-font-color);
        }
    
        .icon {
            margin: 0 auto;
            position: relative;
            z-index: var(--z-index-local-over-2);

            &::before{
                position: absolute;
                background-color: var(--white-color-lightest);
                content: "";
                width: 50%;
                height: 50%;
                border-radius: 50%;
                top: 0%;
                left: 0%;
                bottom: 0%;
                right: 0%;
                margin: auto;
                z-index: var(--z-index-local-under-1);
            }
        }
    }

    .line-item{
        width: 100%;
        flex: 1;
        align-items: baseline;
        color: var(--primary-color-main);

        .line-image {
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background-color: var(--primary-color-main);
            margin-bottom: 24px;
            transition: background-color 0.3s;
        }

        &.disable {
            .line-image {
                background-color: var(--disabled-font-color);
            }
        }
    }
}
#AdminCharacterCreate {
    .button-row {
        width: 100%;
    }

    .card-block {
        width: 100%;
    }

    .card-line {
        width: 6px;
        height: 24px;
        background-color: var(--primary-color-main);
    }

    .card-title {
        margin-left: 16px;
        font-size: var(--text-size-x-large);
    }

    .card-title-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .check-item-row {
        margin-bottom: 32px;
    }

    .stepper-wrapper {
        padding-top: 0;
        padding-bottom: 32px;
        .stepper{
            width: 75%;
        }
    }

    .step-icon{
        opacity: 0;
        transition: opacity 0.3s;
        color: var(--font_active_hidden_color);
        
        &.show{
            opacity: 1
        }
    }

    .cancel-button {
        font-weight: 400;
    }

    .create-select-area{
        opacity: 0;
        transition: opacity 0.3s;
        
        &.show{
            opacity: 1
        }
    }
    
    .create-select-row{
        opacity: 0;
        height: 0;
        
        &.show{
            opacity: 1;
            height: fit-content;
        }
    }

    .male-button{
        color: var(--male-color);
        border: 1px solid var(--male-color);

        &:hover{
            color: var(--white-color-light);
            background-color: var(--male-color);
        }
    }

    .sound-button{
        width: 32px;
        height: 32px;
    }

    .video-select{
        height: 132px;
        width: calc(130px * (16 / 9));
    }

    .video-button{
        width: 100%;
        padding: 0;
        overflow: hidden;
        border: solid 1px var(--border-color);
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-color: var(--white-color-lightest-transparent-25);
            z-index: var(--z-index-local-over-1);
            opacity: 0;
            transition: opacity 0.3s;
        }

        &:hover::after {
            opacity: 1;
        }

        .check-mark {
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            top: -11px;
            right: -11px;
            border-radius: 11px;
            background-color: var(--primary-color-main);
            z-index: var(--z-index-local-over-2);
            opacity: 0;
            transition: opacity 0.3s;
            color: var(--white-color-lightest);
            padding-top: 1px;
            padding-left: 1px;
        }

        &.selected {
            box-shadow: 0 0 0 2.5px var(--primary-color-main);

            .check-mark {
                opacity: 1;
            }
        }

        .video-label {
            padding: 16px 8px;
            border-bottom: solid 1px var(--border-color);
        }

        .button-label {
            height: 100%;
        }

        .video {
            border-radius: 0px 4px 4px 0px;
        }
    }

    .select-option{
        border-radius: 8px;
        position: relative;
        .check-mark{
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            top: -11px;
            right: -11px;
            border-radius: 11px;
            background-color: var(--primary-color-main);
            z-index: var(--z-index-local-over-2);
            opacity: 0;
            transition: opacity 0.3s;
            color: var(--white-color-lightest);
            padding-top: 1px;
            padding-left: 1px;
        }
        &.selected{
            box-shadow: 0 0 0 3px var(--primary-color-main);
            .check-mark{
                opacity: 1;
            }
        }
    }
}
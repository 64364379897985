.color-picker {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;

	.handle-button, .pick-button {
		border: 1px solid var(--white-color-light);
		cursor: pointer;
		transition: box-shadow 0.3s;

		&:hover{
			box-shadow: var(--omni-shadow-hidden);
		}
	}

	.handle-button {
		width: 30px;
		height: 30px;
		border-radius: 15px;
	}

	.pick-button-area{
		position: relative;
		.pick-button {
			width: 34px;
			height: 34px;
			border-radius: 17px;

			&.conic{
				background: conic-gradient(
					red, yellow, lime, cyan, blue, magenta, red
				);
			}
		}
		
		.pick-mark{
			color: var(--white-color-lightest);
			position: absolute;
			top: 0;
			bottom: 2px;
			left: 0;
			right: 0;
			margin: auto;
		}

		&.selected{
			.pick-button {
				transition: box-shadow 0s;
				border: solid 3px var(--white-color-lightest);
				box-shadow: 0 0 0 4px var(--primary-color-main);
			}
		}
	}

	.hex-input-area{
		border-radius: 8px;
		border: solid 1px var(--border-color);
		overflow: hidden;

		.label{
			background-color: var(--disabled-bg-color);
			width: 96px;
		}

		.hex-input{
			border-radius: 0;
			border: none;
		}

		.hex-input-button{
			border-radius: 0;
			width: 96px;
		}
	}

	.picker-palette-area {
		position: fixed;
		z-index: var(--z-index-overlay);

		.picker-palette-area-group{
			position: relative;
			.picker-palette{
				width: 296px;
				height: 220px;
				position: relative;
				z-index: var(--z-index-local-default);

				&.analog-picker{
					height: 308px;
				}
				.sketch-picker{
					box-shadow: none !important;
					width: 244px !important;

					& > :nth-child(3),
					& > :nth-child(4) {
						display: none !important;
					}
				}
			}
			.picker-palette-shadow{
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				box-shadow: var(--omni-shadow-hidden);
				z-index: var(--z-index-local-under-1);
				border-radius: 8px;
				background-color: transparent;

				&:before {
					background-color: #fff;
					content: "";
					height: 20px;
					left: -10px;
					position: absolute;
					top: 50%;
					transform: translateY(-50%) rotate(45deg) skew(-12deg, -12deg);
					width: 20px;
					z-index: var(--z-index-local-under-2);
					box-shadow: var(--omni-shadow-hidden);
				}
			}
		}

	}

	.picker-area {
		position: absolute;
		z-index: var(--z-index-local-over-2);
	}

	.close-button {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
.preview-iframe-wrapper{
  position: relative;
  width: 100%;
  height: 100%;

  .preview-iframe {
    width: 100%;
    height: 100%;
    background-color: white;
  }

  .editor-preview-loading{
    background-color: var(--color-bg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  
    .loader{
      font-size: 48px;
      color: var(--dark-color-light);
    }
  }
}
#ev_radio {
	--radio-width:  20px;
	--radio-height: 20px;

	.labelwidth-50 {
		width: 50px;
	}
	.labelwidth-65 {
		width: 65px;
	}
	.labelwidth-100 {
		width: 100px;
	}
	.labelwidth-150 {
		width: 150px;
	}
	.labelwidth-200 {
		width: 200px;
	}

	&.horizontal{
		display: flex;
		flex-direction: row;

		> :not(:first-child) {
			margin-left: 16px;
		}

		.radio-label{
			margin-left: 8px;
		}
	}

	&.vertical{
		display: flex;
		flex-direction: column;

		> :not(:first-child) {
			margin-top: 16px;
		}
	}

	.radio-wrapper{
		display: flex;
		flex-direction: row;
		align-items: center;
		height: var(--radio-height);
	}

	.radio-input {
		display: block;
		width:  var(--radio-width);
		height: var(--radio-height);
		position: relative;

		&input[type="radio"] {
			width: 100%;
			height: 100%;
			border: 2px solid var(--dark-color-main);
			border-radius: 50%;
			vertical-align: -2px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;
			transition: border-color 0.2s ease-in-out;
		}
		
		&input[type="radio"]:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			width: 60%;
			height: 60%;
			border-radius: 50%;
			background: var(--dark-color-main);
			content: '';
			transition: transform 0.3s ease-in-out;
		}
		
		&input[type="radio"]:checked:before {
			transform: translate(-50%, -50%) scale(1);
		}

		&.loading
		{
			border-radius: 50%;
		}
	}
	
	.radio-label{
		display: block;
		margin-top: calc(var(--radio-height) * 0.15);
		line-height: calc(var(--radio-height));
		margin-left: 8px;
	}


}

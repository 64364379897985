.menu-drawer{
  --menu-expand-width : 240px;
  --menu-shrink-width : 64px;

  min-width: var(--menu-shrink-width);
  max-width: var(--menu-shrink-width);
  background-color: var(--white-color-lightest);
  color: var(--dark-color-dark);
  
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: min-width 0.3s ease;
  box-shadow: var(--omni-shadow-hidden);
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;

  z-index: var(--z-index-menu);
  -webkit-overflow-scrolling: touch;
  position: relative;
  top: 0;
  outline: 0;
  left: 0;
  border-right: 1px solid var(--white-color-dark);

  overflow-x: clip;

  /* モバイル */
  @media (width < 752px) {
    min-width: 0px;
    max-width: 0px;
  }

  .menu-header{
    border-bottom: 1px solid var(--white-color-dark);
    padding: 8px;
    height: 48px;

    .logo{
      object-fit: contain;
      height: auto;
      width: 0px;
      opacity: 0;
      transition: opacity 0.3s;
    }

    .menu-switch-button{
      width: 100%;
      height: 32px;
      min-width: 32px;
      min-height: 32px;


      &.hidden{
        border-radius: 8px;
      }
    }
  }

  .button{
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 14px;

    &.hidden{
      border-radius: 0px;
    }
  }

  .menu-item{
    max-width: var(--menu-expand-width);
    min-width: var(--menu-expand-width);
    
    .button{
      padding-right: 10px;

      .button-label{
        justify-content: space-between;
      }
    }

    .menu-item-icon-area{
      width: var(--menu-shrink-width);
      min-width: var(--menu-shrink-width);
    }

    &.selected{
      transition: color 0.3s background-color 0.3s;
      *{
        color: var(--primary-color-main);
      }

      .button{
        background-color: var(--primary-color-main-transparent-25);
      }
    }
  }

  .edge-group{
    padding: 16px 8px;

    .logout-item{
      max-width: calc(var(--menu-expand-width) - 32px);
      min-width: calc(var(--menu-expand-width) - 32px);

      opacity: 0;
      transition: opacity 0.3s;

      .logout-label{
        width: 100%;
        white-space: no-wrap;
        transition: width 0.3s;
      }

      .button{
        border-radius: 8px;

        .button-label{
          justify-content: space-between;
        }
      }

      .logout-item-icon-area
      {
        width: var(--menu-shrink-width);
        min-width: var(--menu-shrink-width);
      }

      &.shrink{
        max-width: calc(var(--menu-shrink-width) - 16px);
        min-width: calc(var(--menu-shrink-width) - 16px);

        
      .logout-item-icon-area
      {
        max-width: calc(var(--menu-shrink-width) - 16px);
        min-width: calc(var(--menu-shrink-width) - 16px);
      }
  
        opacity: 1;
      }
    }
  }

  &.open {  
    min-width: var(--menu-expand-width);
    box-shadow: var(--omni-shadow);

    .logo{
      width: 88px;
      opacity: 1;
    }

    .menu-switch-button{
      width: 32px;
      height: 32px;
    }

    .edge-group{
      .logout-item{
        opacity: 1;
  
        &.shrink{
          opacity: 0;
        }
      }
    }
  }
}
#AdminUserEdit{
    .input-row-area{
        width: 100%;
      }
    .input-label-text{
        text-align: left;
      }
    .update-button{
      max-width: 10%
    }
}
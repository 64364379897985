
.page-content {
    width: 100%;
    min-width: var(--page-min-width);
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    background-color: var(--color-bg);

    --page-header-height: 96px;

    /* モバイル */
    @media (width < 752px) {
        --page-min-width: 100vw;
    }
    
    /* タブレット */
    @media (752px <= width < 1122px) {
        --page-min-width: 320px;
    }
    
    /* PC */
    @media (1122px <= width) {
        --page-min-width: 480px;
    }

    .scene-root{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .page-wrapper {
        width: 100%;
        min-width: var(--page-min-width);
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex: 1;
    }

    .page-header-container {
        width: 100%;
        margin-left: auto;
        box-sizing: border-box;
        margin-right: auto;
        padding-left: 16px;
        padding-right: 16px;
        max-width: 1080px;
        min-width: var(--page-min-width);
        height: 100%;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .page-header-title{
            font-size: var(--text-size-x-large);
            font-weight: 800;
        }
        .page-header-detail{
            font-size: var(--text-size-normal);
        }
    }

    .page-header {
        width: 100%;
        min-width: var(--page-min-width);
        height: var(--page-header-height);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .page-scroll-parent {
        width: 100%;
        height: calc(100vh - var(--page-header-height));
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        overflow-y: scroll;
        overflow-x: hidden;

        &.isHeader
        {
            height: calc(100vh - var(--page-header-height) - var(--header-height));
        }
    }

    .page-container-parent {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &.isHeader
        {
            height: calc(100vh - var(--page-header-height) - var(--header-height));
        }
    }

    .page-container {
        width: 100%;
        max-width: 1080px;
        height: fit-content;
        margin-left: auto;
        box-sizing: border-box;
        margin-right: auto;
        padding-left: 16px;
        padding-right: 16px;
        min-width: var(--page-min-width);
        padding: 16px;
        margin-bottom: 60px;
    }

    .side-content {
        height: 100%;
        width: 100%;
        min-width: 360px;
        max-width: 360px;
        transition: min-width 0.3s;
        overflow-x: hidden;
        flex: 2;

        .side-fixed-button {
            position: fixed;     /* 位置を固定 */
            right: 16px;         /* 右端から0pxの位置に設定 */
            top: 50%;            /* 画面の上から50%の位置に設定 (画面の中央に垂直方向で配置) */
            transform: translateY(-50%);  /* Y軸方向の中心を基点として移動させることで、ボタンが完全に垂直中央にくるよう調整 */
        }

        &.side-close{
            width: 0px !important;
            min-width: 0px !important;
            flex: 0 !important;
        }
    }

    .side-scroll-parent {
        width: 320px;
        height: calc(100vh - var(--page-header-height));
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex: 1;
        overflow-y: scroll;
    }

    .side-container {
        width: 320px;
        min-width: 320px;
        margin-left: auto;
        box-sizing: border-box;
        margin-right: auto;
        padding-left: 16px;
        padding-right: 16px;
        height: 100%;
    }
}
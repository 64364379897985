#date-radio{
  /* ラジオボタンを非表示にする */
  .date-radio-input {
      display: none;
      align-items: left;
  }

  /* ラジオボタンを横並びにする */
  display: flex;
  justify-content: flex-start; /* 左寄せを設定 */
  
  /* カスタムラジオボタンのスタイル */
  .date-radio-wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 4px; 
  }

  .date-radio-wrapper:last-child {
    margin-right: 0; /* 最後の要素の右側マージンを0にする */
  }
  
  .date-radio-label {
    min-width: 96px; /* ラベルの幅を固定 */
    height: 28px; /* ラベルの高さを固定 */
    margin: 0px 0px 0px 0px;
    border: 1px solid var(--border-weak-color);
    border-radius: 24px;
    background-color:var(-white-color-lightest);
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--text-size-small);
    }

  /* ホバー時のスタイル */
  .date-radio-label:hover {
    background-color: var(--dark-color-main);
    color: var(--white-color-main);
  }

  /* 選択時のスタイル */
  .date-radio-input:checked + .date-radio-label{
    background-color: var(--dark-color-dark);
    color: var(--white-color-lightest);
  }

  .label-icon {
    margin-right: 4px; /* 最後の要素の右側マージンを0にする */
  }
  
  /* ローディング状態のスタイル */
  .loading {
  }
}
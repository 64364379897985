#AdminUserLearningEdit{
    .middle-button{
        max-width: 15%
    }
    .long-button{
        max-width: 20%
    }
    .vlong-button{
        max-width: 25%
    }
    .input-label-text{
      text-align: left;
      min-width: 10%;
    }
    .middle-input{
        max-width: 55%
    }
    .addmergin{
        margin-top: 32px;
        padding-bottom: 32px;
    }
}
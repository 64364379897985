.modal-parent {
	display: none;
	justify-content: center;
	align-items: center;
	padding-bottom: 10%;
	background-color: var(--dark-color-dark-transparent-25);
	color: var(--font_inactive_color);
	z-index: var(--z-index-modal);

	position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

	//開いている時のみクリックイベントを受け付ける
	pointer-events: none;
	&.show{
		pointer-events: all;
		display: flex;
		opacity: 1;
	}

	.ev-modal-dialog {
		height: 100%;
		min-width : 720px;
		min-height: 240px;
		height: fit-content;
		max-width: calc(100vw - 48px);
		margin: 0;
	}
	.modal-dialog-centered {
		justify-content: center;
	}
	&_title,.ev-modal-title {
		font-size: var(--text-size-large);
		height: var(--text-size-large);
	}

	.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ev-modal-container {
		background-color: var(--white-color-lightest);
		min-height: 100px;

		//デフォルトサイズ
		width: 720px;
		height: fit-content;

		padding: 8px;
		position: relative;
		z-index: 20;
		box-shadow: var(--omni-shadow);
		.row,
		.col {
			margin: 0;
			padding: 0;
		}

		font-size: var(--text-size-normal);
		overflow: hidden;
		padding: 16px;
		border-radius: 8px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		hr{
			width: 200vw;
			margin-block-start:0%;
			transform: translateX(-50vw);
			color: var(--border-color);
		}

		.ev-modal-header{
			flex:1;
			height: 36px;
			max-height: 36px;
			min-height: 36px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;

			.closebutton{
				width: 36px;
				height: 36px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.ev-modal-body{
			flex:1;
			width: 100%;
		}

		
		.ev-modal-footer{
			flex:1;
			height: 36px;
			max-height: 36px;
			min-height: 36px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.button-row {
				flex:1;
				width: 100%;
				height: 36px;
				max-height: 36px;
				min-height: 36px;
				display: flex;
				justify-content: flex-end;
	
				.button-element{
					height: 100%;
					&:last-child{
						margin-left: 8px;
					}

					.button{
						min-width: 120px;
					}
				}
			}
		}

	}
	&.bginvisible {
		background: none;
	}
	&.notbgevents {
		pointer-events: none;
	}

	#close {
		width: 25px;
		height: 25px;
		right: 0;
		top:0;
		padding: 0;
		border: none;
		border-radius: 4px;
		color: var(--danger-color-dark);
		background-color: var(--white-color-lightest);
		cursor: pointer;
		&:hover {
			color: var(--danger-color-dark);
		}
		@media (max-width: 575px)	{
			left: 0;
		}
	}
	.modal{
		&-right {
			margin-left: 70vw;
			margin-top: 45px;
		}
		&-left {
			margin-left: 16vw;
			margin-top: 45px;
		}
		&-background {
			background-color: #0000007f;
		}
	}
}
.button {
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white-color-light);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  transition: all 0.3s;
  border: none;
  padding: 8px;
  border-radius: 8px;

  font-weight: 600;
  letter-spacing: 2px;
  font-size: 16px;

  .button-label{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &.medium{
    min-height: 48px;
  }

  &.hidden{
    color: var(--dark-color-dark);
    border-radius: 8px;
    background-color: transparent;
    &:hover{
      background-color: var(--dark-color-dark-transparent-10);
    }
  }
  &.hidden-border{
    color: var(--dark-color-dark);
    border-radius: 8px;
    background-color: transparent;
    &:hover{
      background-color: var(--dark-color-dark-transparent-10);
    }
  }

  &.primary{
    border: 1px solid var(--primary-color-main);
    background-color: var(--primary-color-main);

    &:hover{
      background-color: var(--primary-color-dark);
      border: 1px solid var(--primary-color-dark);
    }

    &:disabled, &.disabled{
      border: 1px solid var(--primary-color-disabled);
      background-color: var(--primary-color-disabled);
      pointer-events: none;
    }
  }

  &.primary-border{
    color: var(--primary-color-main);
    border: 1px solid var(--primary-color-main);
    background-color: transparent;

    &:hover{
      color: var(--white-color-light);
      background-color: var(--primary-color-main);
    }

    &:disabled, &.disabled{
      border: 1px solid var(--disabled-border-color);
      background-color: transparent;
      pointer-events: none;
      color: var(--disabled-font-color);
    }
  }

  &.secondary{
    color: var(--font_active_sub_color);
    border: 1px solid var(--dark-color-light);
    background-color: var(--dark-color-light);

    &:hover{
      background-color: var(--dark-color-main);
      border: 1px solid var(--dark-color-main);
    }
  }

  &.secondary-border{
    color: var(--font_active_sub_color);
    border: 1px solid var(--border-color);
    background-color: transparent;

    &:hover{
      background-color: var(--dark-color-dark-transparent-10);
    }
  }

  &.danger{
    border: 1px solid var(--danger-color-main);
    background-color: var(--danger-color-main);

    &:hover{
      background-color: var(--danger-color-dark);
      border: 1px solid var(--danger-color-dark);
    }

    &:disabled, &.disabled{
      border: 1px solid var(--danger-color-disabled);
      background-color: var(--danger-color-disabled);
      pointer-events: none;
    }
  }

  &.danger-border{
    color: var(--danger-color-main);
    border: 1px solid var(--danger-color-main);
    background-color: transparent;

    &:hover{
      color: var(--white-color-light);
      background-color: var(--danger-color-main);
    }

    &:disabled, &.disabled{
      border: 1px solid var(--disabled-border-color);
      background-color: transparent;
      pointer-events: none;
      color: var(--disabled-font-color);
    }
  }

  &.warning{
    border: 1px solid var(--warning-color-main);
    background-color: var(--warning-color-main);

    &:hover{
      background-color: var(--warning-color-dark);
      border: 1px solid var(--warning-color-dark);
    }

    &:disabled, &.disabled{
      border: 1px solid var(--warning-color-disabled);
      background-color: var(--warning-color-disabled);
      pointer-events: none;
    }
  }

  &.warning-border{
    color: var(--warning-color-main);
    border: 1px solid var(--warning-color-main);
    background-color: transparent;

    &:hover{
      color: var(--white-color-light);
      background-color: var(--warning-color-main);
    }

    &:disabled, &.disabled{
      border: 1px solid var(--warning-color-disabled);
      pointer-events: none;
    }
  }

  &.success{
    border: 1px solid var(--success-color-main);
    background-color: var(--success-color-main);

    &:hover{
      background-color: var(--success-color-dark);
      border: 1px solid var(--success-color-dark);
    }

    &:disabled, &.disabled{
      border: 1px solid var(--success-color-disabled);
      background-color: var(--success-color-disabled);
      pointer-events: none;
    }
  }

  &.success-border{
    color: var(--success-color-main);
    border: 1px solid var(--success-color-main);
    background-color: transparent;

    &:hover{
      color: var(--white-color-light);
      background-color: var(--success-color-main);
    }

    &:disabled, &.disabled{
      border: 1px solid var(--success-color-disabled);
      pointer-events: none;
    }
  }
}
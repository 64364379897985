.pagination {
    display: flex;
    gap: 8px;
    align-items: center;
    .page-button {
        padding: 8px 12px;
        border: 1px solid var(--dark-color-lightest);
        background-color: var(--white-color-lightest);
        cursor: pointer;
    }

    .page-button.active {
        background-color: var(--primary-color-dark);
        color: var(--white-color-lightest);
        font-weight: bold;
    }
    .page-button:hover  {
        background-color: var(--primary-color-light);
        color: var(--white-color-lightest);
        font-weight: bold;
    }
    .ellipsis {
        padding: 8px;
    }
}
#PaymentSetting{

    .page-container{
        padding: 16px;
    }

    .card-input-area {
        width: 416px;
        padding: 0px 32px;
        padding-bottom: 32px;
    }
}
.header {
    box-sizing: border-box;
    height: var(--header-height);
    width: 100%;
    z-index: var(--z-index-header);
    background-color: var(--white-color-lightest);
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: var(--white-color-dark) 1px solid;

    .header-container{
      flex: 1;
      height: 100%;
      max-width: 1440px;
    }
  }
.textinput {
  display: block;
  width: 100%;
  height: 40px;
  padding: 12px;
  line-height: 12px;
  color: var(--dark-color-dark);
  background-color: var(--input-bg-color);
  background-clip: padding-box;
  border: 1px solid var(--border-color);
  appearance: none;
  border-radius: 4px;
  transition: all 0.15s;

  &::placeholder {
    color: #c2c8d2;
    opacity: 1;
  }

  &:focus {
      background-color: var(--input-bg-color);
      border: 1px solid var(--primary-color-main);
      outline: 0;
  }

  &:disabled {
    background: var(--disabled-bg-color);
    color: var(--disabled-font-color);
    cursor: default;
    -webkit-appearance: none;
    border: 1px solid var(--disabled-border-color);
  }
}
#error-modal {

  .ev-modal-container {
    width: 520px;
  }

  .error-modal-message{
    min-height: 96px;
  }
  .error-icon{
    color: var(--danger-color-main)
  }
}
.fitList {
	display: flex;
	flex-direction: column;
	overflow: hidden auto;

	.list-row {
		display: flex;
		height: fit-content;
		min-height: 35px;
	}
	.title {
		background-color: var(--color-bg);
		font-size: var(--text-size-small);
		margin: 0;
		position: sticky;
		top: 0;
		z-index: var(--z-index-local-over-1); 
	}
	.listtitle {
		align-items: center;
		border-bottom: solid 1px var(--border-weak-color);
		border-top: solid 1px var(--border-weak-color);
		// border-right: solid 1px var(--border-weak-color);
		color: var(--font_inactive_color);
		display: flex;
		overflow: hidden;
		padding: 0 8px;

		.cell-item {
			display: flex;
		}
		&:last-child{
			border-right: none;
		}
	}

	.list-group-item.list-group-item-action:hover {
		cursor: pointer;
	}

	.listbody {
		font-size: var(--text-size-normal);
		.list-row {
			&:hover {
				background-color: var(--hover-color);
				color: var(--font_active_color);
			}
		} 

		.listitem {
			display: flex;
			align-items: center;
			border-bottom: solid 1px var(--border-weak-color);
			// border-right: solid 1px var(--border-weak-color);
			padding: 0 8px;
			min-height: 30px;
			white-space: pre-wrap;
			overflow: hidden;
			text-overflow: ellipsis;

			textarea {
				background-color: var(--white-color-lightest);
				border-radius: 3px;
				margin: 6px 0;
				&:hover {
					border: 1px solid var(--border-active-color);
				}
			}

			&:last-child {
				border-right: none;
			}

			.dropicon{
				color: rgba(255, 255, 255, 0);
			}		
	
			&:hover{
				.dropicon{
					color: var(--font_inactive_color);
				}	
			}
		}
	}
	.cell-item {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: pre-wrap;
		.cell-title-text {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		&.sort-cell {
			align-items: center;
			justify-content: space-between;
		}

		&.loading
		{
			border-radius: 8px;
		}
	}
	.sort-button {
		height: 24px;
		width: 24px;
	}
	.sorting-button {
		color: var(--font_active_color);
	}
}

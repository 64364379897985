.video {
  &.vertical-fit{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .video-container{
      position: absolute;
      z-index: 1;
      width: 100%;
      height: fit-content;
      max-height: 100%;

      // 上下中央揃えクリップ
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .video-image{
        width: 100%;
        height: auto;
      }
    }
  }

  &.horizontal-fit{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  
    .video-container{
      position: absolute;
      z-index: 1;
      width: fit-content;
      height: 100%;
      max-width: 100%;
  
      // 上下中央揃えクリップ
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .video-image{
        width: auto;
        height: 100%;
      }
    }
  }
}
.tool-tip {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;

	.handle-button {
		width: 18px;
		height: 18px;
		border-radius: 15px;
		border: 1px solid var(--white-color-light);
		cursor: pointer;
		transition: box-shadow 0.3s;
		background-color: var(--font_active_sub_color);
		color: var(--white-color-lightest);
		display: flex;
		justify-content: center;
	}

	.tool-tip-float-area {
		position: fixed;
		z-index: var(--z-index-overlay);

		.tool-tip-float-area-group{
			position: relative;
			.tool-tip-float{
				width: 296px;
				height: fit-content;
				position: relative;
				z-index: var(--z-index-local-default);
				background-color: var(--dark-color-dark-transparent-75);
				color: var(--white-color-lightest);
				font-size: var(--text-size-small);
				padding: 8px 12px;
			}
			.tool-tip-float-shadow{
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				box-shadow: var(--omni-shadow-hidden);
				z-index: var(--z-index-local-under-1);
				border-radius: 8px;
				background-color: transparent;
			}
		}

	}

	.close-button {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}